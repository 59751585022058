export const environment = {
    production: true,
    banorte:false,
    ente: "H. Ayuntamiento de Badiraguato",
    administracion: "2024 - 2027",
    url_escudo: "./assets/badiraguato/escudo.png",
    url_administracion: "./assets/badiraguato/FOOTER.jpg",
    url_footer: "./assets/badiraguato/FOOTER.jpg",
    direcciones: [
        "Leyva Solano y Álvarez Borboa",
        "C.P. 80500",
        "Badiraguato, Sinaloa"
    ],
    telefonos: [
        "697-741-01-08",
        "+52 697-741-01-08"
    ],
    emails: [
        "comunicacion@badiraguato.gob.mx"
    ],
    url_aviso: "",
    url_webapi: "https://pagosbadiraguato.somee.com/api/",
    url_sitio: "https://pagosbadiraguato.somee.com/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/',
    cve_municipio: "021",
    municipio: "BADIRAGUATO",
    firebase: {
        apiKey: "AIzaSyAge88E-U-DkC7CiixsEcYXQqOPxB2mARk",
        authDomain: "pagosbadiraguato.firebaseapp.com",
        projectId: "pagosbadiraguato",
        storageBucket: "pagosbadiraguato.appspot.com",
        messagingSenderId: "365235753989",
        appId: "1:365235753989:web:9751b050301d3522ec786c",
        measurementId: "G-7LQ9WKDQS8"
      },
    leyenda_fundamento: "Ley de ingresos del municipio de Badiraguato para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "1234",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/elfuerte/img_ayuda_1.jpg',
    img_ayuda_2: '../assets/elfuerte/img_ayuda_2.jpg',
    img_ayuda_3: '../assets/elfuerte/img_ayuda_3.jpg'
};